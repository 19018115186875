<template>
  <div>
    <b-modal
      v-model="showLogin"
      hide-footer
      cons
      centered
      lg="4"
      class="d-flex align-items-center auth-bg px-2 p-lg-5"
      no-close-on-esc
    >
      <!--
      no-close-on-backdrop
      hide-header-close-->
      <b-col class="px-xl-2 mx-auto">
        <b-card-text class="mb-2 font-weight-bold">
          Please sign-in to your account to continue
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginForm">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- mobile -->
            <b-form-group label="Mobile" label-for="mobile">
              <validation-provider #default="{ errors }" name="Mobile" rules="required|min:9">
                <b-form-input
                  id="mobile"
                  ref="mobile"
                  v-model="userMobile"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  name="mobile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="dark"
              block
              class="mb-2 mt-2"
              :disabled="loggingIn"
              @click="sendOTP"
            >
              <b-spinner v-if="sendingOTP" style="margin:1px 3px" small />
              SEND OTP
            </b-button>
          </b-form>
        </validation-observer>
      </b-col>
    </b-modal>
    <b-modal
      v-model="nameModal"
      title="Customer Name"
      lg="4"
      class="d-flex align-items-center auth-bg px-2 p-lg-5"
      hide-footer
      cons
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <b-col class="px-xl-2 mx-auto">
        <b-card-text class="mb-2 font-weight-bold">
          Please enter full name for delivery
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginForm">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- Name -->
            <b-form-group label="Name" label-for="name">
              <validation-provider #default="{ errors }" name="Name" rules="required|min:3">
                <b-form-input
                  id="name"
                  ref="name"
                  v-model="customerName"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  name="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="dark"
              block
              class="mb-2 mt-2"
              :disabled="loggingIn || customerName.length < 3"
              @click="update"
            >
              <b-spinner v-if="loggingIn" style="margin:1px 3px" small />
              Save & continue
            </b-button>
          </b-form>
        </validation-observer>
      </b-col>
    </b-modal>
    <b-modal
      v-model="otpModal"
      hide-footer
      cons
      centered
      lg="4"
      class="d-flex align-items-center auth-bg px-2 p-lg-5"
      no-close-on-esc
    >
      <!--
      no-close-on-backdrop
      hide-header-close-->
      <b-col class="px-xl-2 mx-auto">
        <b-card-text class="mb-2">
          Please enter OTP sent to {{ userMobile }} to sign-in to your account
        </b-card-text>

        <!-- form -->
        <validation-observer ref="verifyOTPForm">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- OTP -->
            <b-form-group label="OTP" label-for="otp">
              <validation-provider #default="{ errors }" name="OTP" rules="required|min:4">
                <b-form-input
                  id="otp"
                  ref="otp"
                  v-model="otp"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  name="otp"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="dark"
              block
              class="mb-2 mt-2"
              :disabled="loggingIn"
              @click="login"
            >
              <b-spinner v-if="loggingIn" style="margin:1px 3px" small />
              SIGN IN
            </b-button>
          </b-form>
        </validation-observer>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require,no-console */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSpinner,
  BCol,
  BModal,
  BFormGroup,
  BFormInput,
  BCardText,
  BForm,
  BButton,
  VBModal,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import { required, min } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'

export default {
  components: {
    BSpinner,
    BCol,
    BFormGroup,
    BFormInput,
    BCardText,
    BModal,
    // BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      nameModal: false,
      customerName: '',
      mobileInput: null,
      otp: null,
      status: '',
      otpModal: false,
      userMobile: null,
      // validation rulesimport store from '@/store/index'
      required,
      min,
      sendingOTP: false,
      loggingIn: false,
    }
  },
  computed: {
    showLogin: {
      get() {
        return store.state.user.showLogin
      },
      set() {
        this.toggleLogin()
      },
    },
    user: {
      get() {
        return store.state.user.user
      },
      set() {},
    },
  },
  watch: {
    showLogin(value) {
      if (value) {
        const that = this
        setTimeout(() => {
          const phoneInputField = document.querySelector('#mobile')
          that.mobileInput = intlTelInput(phoneInputField, {
            preferredCountries: ['us', 'co', 'in', 'de'],
            separateDialCode: true,
            utilsScript:
              'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.11/js/utils.js',
          })
        }, 1)
      }
    },
  },
  async mounted() {
    // const x = await fetch('http://localhost:8888/test')
    // console.log(x)
    this.nameModal = this.user && this.user.mobile && !this.user.name
  },
  methods: {
    ...mapMutations({
      updateUser: 'user/UPDATE_USER',
      toggleLogin: 'user/toggleLogin',
    }),
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    async sendOTP() {
      try {
        this.sendingOTP = true
        const success = await this.$refs.loginForm.validate()
        console.log(this.mobileInput.getNumber())
        if (success) {
          /**
           * @type {Object}
           */
          const response = await axios.post(
            'https://verify-9756-breknn.twil.io/start-verify',
            {
              to: this.mobileInput.getNumber(),
              channel: 'sms',
              locale: 'en',
            }
          )
          if (response.data.success !== true) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error!',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `${response.data.error.message || 'something went wrong! #878dfgh'}`,
              },
            })
            return
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'OTP sent successfully',
              icon: 'CheckIcon',
              variant: 'dark',
            },
          })
          this.otp = ''
          this.otpModal = true
        }
      } catch (e) {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${e.response.data.error.message}`,
          },
        })
      } finally {
        this.sendingOTP = false
      }
    },
    async login() {
      try {
        this.loggingIn = true
        const success = await this.$refs.verifyOTPForm.validate()
        if (success) {
          /**
           * @type {Object}
           */
          const response = await useJwt.login({
            to: this.mobileInput.getNumber(),
            otp: this.otp,
          })
          if (response.data.type !== 'success') {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error!',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `${response.data.display || 'something went wrong! #878dfgh'}`,
              },
            })
            return
          }
          const { user } = response.data
          useJwt.setToken(response.data.token)
          this.updateUser({ user })
          this.$toast({
            component: ToastificationContent,
            position: 'top-center',
            props: {
              title: `Welcome ${user.name || user.mobile}`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          this.showLogin = false
          this.otpModal = false
          if (!user.name) this.nameModal = true
          else await this.$router.push('review-order')
        }
      } catch (e) {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${e.message}`,
          },
        })
      } finally {
        this.loggingIn = false
      }
    },
    async update() {
      // eslint-disable-next-line no-unreachable
      try {
        this.loggingIn = true
        const response = await this.$http.patch('jwt/user', {
          to: this.user.mobile,
          name: this.customerName,
        })
        if (response.data.type !== 'success') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${response.data.display || 'something went wrong! #878dfgh'}`,
            },
          })
          return
        }
        this.user.name = this.customerName
        this.updateUser({ user: this.user })
        this.nameModal = false
      } catch (e) {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${e.message}`,
          },
        })
      } finally {
        this.loggingIn = false
      }
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/pages/page-auth.scss';
</style>
